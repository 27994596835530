import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import cx from 'classnames';
import Image from "next/legacy/image";
import Link from 'next/link';
import { useRouter } from 'next/router';
import OverlayPortal from 'components/Others/OverlayPortal';
import { DEFAULT_BLUR_IMAGE_DATA_URL } from 'constants/blurImage';
import useDeviceSupportsTouch from 'hooks/useDeviceSupportsTouch';
import useWindowSize from 'hooks/useWindowSize';
import { HeaderNavigationCategory } from './Shared/useHeaderHelpers';
import DiscountBundle from 'components/Icons/DiscountBundle';
import { stringToSlug } from 'functions/slug';
import menuImageStore from 'zus/menuImageStore';
import ShoppingIconNoCircle from 'components/Icons/ShoppingIconNoCircle';

interface HeaderNavigationCategoryOverlayProps {
  visible: boolean;
  activeCategory?: HeaderNavigationCategory;
  // eslint-disable-next-line no-unused-vars
  updateOverlayVisibility?: (visibleState: boolean) => void;
}

const HeaderNavigationCategoryOverlay: FC<HeaderNavigationCategoryOverlayProps> =
  ({ visible, activeCategory, updateOverlayVisibility }) => {
    const [contentBoxSpacing, setContentBoxSpacing] = useState({
      x: 0,
      y: 0
    });

    const [isDeviceSupportsTouch] = useDeviceSupportsTouch();

    const { width } = useWindowSize();

    const contentContainer = useRef<HTMLDivElement>(null);

    const [navigationListElement, setNavigationListElement] =
      useState<HTMLUListElement>();

    const router = useRouter();


    useEffect(() => {
      if (!visible) return;
      updateOverlayVisibility?.(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.asPath]);

    useEffect(() => {
      if (visible) updateOverlayVisibility?.(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    useEffect(() => {
      if (!visible) return;

      setNavigationListElement(
        document.querySelector(
          '[data-header-navigation-list]'
        ) as HTMLUListElement
      );

      if (!navigationListElement) return;

      const navigationListElementRect =
        navigationListElement.getBoundingClientRect();

      const contentBoxSpaceX = navigationListElementRect.left;
      const contentBoxSpaceY = navigationListElementRect.bottom;

      setContentBoxSpacing({
        x: contentBoxSpaceX,
        y: contentBoxSpaceY
      });
    }, [width, visible, navigationListElement]);

    useEffect(() => {
      const mouseOverHandler = (event: MouseEvent) => {
        if (!navigationListElement || !updateOverlayVisibility) return;
        const targetElement = event.target as HTMLElement;
        const isTargetNavigationMenuItem =
          navigationListElement?.contains(targetElement) &&
          !targetElement.hasAttribute('data-ignore-category-overlay');
        const isTargetContentChild =
          contentContainer?.current?.contains(targetElement);

        if (
          visible &&
          !isTargetContentChild &&
          !isTargetNavigationMenuItem &&
          !isDeviceSupportsTouch
        ) {
          updateOverlayVisibility(false);
        }
      };
      const mouseClickHandler = (event: MouseEvent) => {
        if (
          visible &&
          (!isDeviceSupportsTouch ||
            navigationListElement?.contains(event.target as HTMLElement))
        )
          return;

        updateOverlayVisibility?.(false);
      };
      document.addEventListener('mouseover', mouseOverHandler);
      document.addEventListener('click', mouseClickHandler);
      return () => {
        document.removeEventListener('mouseover', mouseOverHandler);
        document.removeEventListener('click', mouseClickHandler);
      };
    });

    const overlayImage = activeCategory && activeCategory?.name && menuImageStore.getState().getImageUrlBySlug(stringToSlug(activeCategory?.name));

    return (
      <OverlayPortal>
        <Transition
          appear={true}
          show={visible}
          className="fixed top-0 left-0 z-[49] h-full w-full">
          <Transition.Child
            enter="transition duration-[250ms] transform"
            enterFrom="-translate-y-full"
            enterTo="translate-y-0"
            leave="transition duration-[250ms] transform"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="-translate-y-full opacity-0"
            className="relative z-10">
            <div
              ref={contentContainer}
              className="relative w-full bg-white py-[30px] shadow-navbarOverlay"
              style={{ top: contentBoxSpacing.y + 'px' }}>
              <div
                style={{ marginLeft: contentBoxSpacing.x + 'px' }}
                className="flex w-full">
                {activeCategory?.name != "Contact Lenses" && <div className={`mr-[30px] grid min-w-[200px] grid-flow-col ${router.locale === 'international' ? 'grid-rows-3 h-[125px]' : 'grid-rows-4'} justify-between gap-x-[30px] text-[14px] shop:mr-[60px] shop:min-w-[255px] shop:gap-x-[60px] shop:text-[16px]`}>
                {activeCategory?.items?.map((navigationItem) => {
                    return navigationItem.name === "Gift Cards" ? (
                      <div key={navigationItem.name}>
                        <a
                          href={'/' + router.locale + navigationItem.path}
                          onClick={() => updateOverlayVisibility?.(false)}
                          className={'transition-colors hover:text-brand-orange text-brand-black100'}>
                          {navigationItem.name}
                        </a>
                      </div>
                    ) : (
                      <div key={navigationItem.name}>
                        <Link
                          prefetch={false}
                          href={navigationItem.path}
                          onClick={() => updateOverlayVisibility?.(false)}
                          className={'transition-colors hover:text-brand-orange text-brand-black100'}>
                          {navigationItem.name}
                        </Link>
                      </div>
                    );
                  })}
                </div>}
                {activeCategory?.name == "Contact Lenses" && activeCategory?.sections?.map((section, idx) => (
                  <div key={idx} className="mr-16 mb-2 last-of-type:mr-20 flex flex-col flex-nowrap">
                    <div className="mb-4 last-of-type:mb-0 font-medium">{section.name}</div>
                    {section.items.map((sectionItem: any) =>
                    (<div key={sectionItem.name} className={cx('mb-4 last-of-type:mb-0', {
                      ' mt-auto': sectionItem.name.includes('View All')
                    })} >
                      <Link
                        prefetch={false}
                        href={sectionItem.path}
                        className={cx('transition-colors hover:text-brand-orange',
                          {
                            'text-brand-black100': !sectionItem.name.includes('View All')
                          },
                          {
                            'text-brand-blue25': sectionItem.name.includes('View All')
                          }
                        )}
                        onClick={() => updateOverlayVisibility?.(false)}>

                        {sectionItem.name}

                      </Link>
                    </div>))}
                  </div>
                ))}

                <div className='min-h-[170px]'>
                  {activeCategory?.name && overlayImage && (<Image
                    key={overlayImage}
                    src={overlayImage}
                    alt={activeCategory.name}
                    placeholder="blur"
                    blurDataURL={DEFAULT_BLUR_IMAGE_DATA_URL}
                    width={598}
                    objectFit="cover"
                    objectPosition="center"
                    layout="intrinsic"
                    height={162}
                    quality={90}
                  />)}

                  <div className='flex gap-[20px]'>
                    {(activeCategory?.name == "Contact Lenses" && (router.locale === 'ca')) &&
                      (
                        <Link prefetch={false} href={'/shop/contact-lenses?bundle=true'}>
                          <div className='text-brand-blue25 flex pt-1 mt-[70px]'>
                            <div className='flex justify-center items-center'>
                              <DiscountBundle />
                              <span className='ml-1'> Discounted Bundle Options</span>
                            </div>
                          </div>
                        </Link>
                      )
                    }

                    {(activeCategory?.name == "Contact Lenses") && (
                      <Link prefetch={false} href={'/re-order-contact-lenses'}>
                        <div className='text-brand-blue25 flex mt-16 pt-1 '>
                          <div className='flex justify-center items-center'>
                            <ShoppingIconNoCircle fill='#ff8202' height={40} width={40} />
                            <span className='ml-1'> Re-order Contact Lenses</span>
                          </div>

                        </div>
                      </Link>
                    )}
                  </div>
                </div>

              </div>
              {activeCategory?.name === "Accessories" &&
                <div key={'View All Accessories'} className="mt-2 text-brand-blue25" style={{ marginLeft: contentBoxSpacing.x + 'px' }}>
                  <Link
                    prefetch={false}
                    href={'/shop/accessories'}
                    onClick={() => updateOverlayVisibility?.(false)}
                    className={'transition-colors hover:text-brand-orange text-brand-blue25'}>

                    View All Accessories

                  </Link>
                </div>
              }
            </div>
          </Transition.Child>
          <Transition.Child
            enter="transition-opacity duration-[250ms]"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-[250ms]"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div
              onClick={() => updateOverlayVisibility?.(false)}
              className="absolute left-0 top-0 h-full w-full bg-black bg-opacity-75"></div>
          </Transition.Child>
        </Transition>
      </OverlayPortal >
    );
  };

export default HeaderNavigationCategoryOverlay;
